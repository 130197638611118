import { SplashActionType } from './../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  splashes: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case SplashActionType.GET_SPLASHES_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.GET_SPLASHES_API_SUCCESS:
      return {
        ...state,
        loading: false,
        splashes: action.payload,
      }

    case SplashActionType.GET_SPLASHES_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
