import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getInviteAPISuccess,
  getInviteAPIFailure,
  getResponseOfInviteAPISuccess,
  getResponseOfInviteAPIFailure,
  updateStatusMessageAPIFailure,
  updateStatusMessageAPISuccess,
  turnOffAPISuccess,
  turnOffAPIFailure,
  getEventAPISuccess,
  getEventAPIFailure,
  contactMeMessageAPISuccess,
  contactMeMessageAPIFailure,
  turnOnAPISuccess, turnOnAPIFailure, getMissedNotificationsSuccess, getMissedNotificationsFailure,
} from './../../actions'
import {
  MessageActionType,
} from './../../types'
import { API, API_BOX } from './../../../utils/api'
import { Util } from './../../../utils'

const getInviteRequest = async (id) => API.get(`message/${id}`)
function* getInviteAPI({ payload }) {
  try {
    const request = yield call(getInviteRequest, payload)
    yield put(getInviteAPISuccess(request))
  } catch (error) {
    yield put(getInviteAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getInvite() {
  yield takeEvery(MessageActionType.GET_INVITE_API, getInviteAPI)
}

const getMissedNotificationRequest = async (macAddress) => API.get(`message-receiver/missed-invites/${macAddress}`)

function* getMissedNotificationAPI({payload}) {
  try {
    const request = yield call(getMissedNotificationRequest, payload)
    yield put(getMissedNotificationsSuccess(request.data))
  } catch (error) {
    yield put(getMissedNotificationsFailure(Util.getErrorLogin(error)))
  }
}

export function* getMissedNotification() {
  yield takeEvery(MessageActionType.GET_MISSED_NOTIFICATIONS_API, getMissedNotificationAPI)
}

const getEventRequest = async (id) => API.get(`event/${id}`)
function* getEventAPI({ payload }) {
  try {
    const request = yield call(getEventRequest, payload)
    yield put(getEventAPISuccess(request))
  } catch (error) {
    yield put(getEventAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getEvent() {
  yield takeEvery(MessageActionType.GET_EVENT_API, getEventAPI)
}

const updateStatusMessageRequest = async (messageId, statusId) => API.put(`message/${messageId}/update-invite`, { statusId })
function* updateStatusMessageAPI({ payload }) {
  try {
    const request = yield call(updateStatusMessageRequest, payload.messageId, payload.statusId)
    yield put(updateStatusMessageAPISuccess(request))
  } catch (error) {
    yield put(updateStatusMessageAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* updateStatusMessage() {
  yield takeEvery(MessageActionType.UPDATE_STATUS_MESSAGE_API, updateStatusMessageAPI)
}

const getResponseOfInviteRequest = async (id) => API.get(`message/${id}/response`)
function* getResponseOfInviteAPI({ payload }) {
  try {
    const request = yield call(getResponseOfInviteRequest, payload)
    yield put(getResponseOfInviteAPISuccess(request))
  } catch (error) {
    yield put(getResponseOfInviteAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getResponseOfInvite() {
  yield takeEvery(MessageActionType.GET_RESPONSE_OF_INVITE_API, getResponseOfInviteAPI)
}


const turnOffRequest = async (messageId) => API.put(`messages/${messageId}/turn-off`)
const turnOffBoxRequest = async () => API_BOX.post(`leds/turn-off`)

function* turnOffAPI({ payload }) {
  try {
    yield call(turnOffBoxRequest)
    const request = yield call(turnOffRequest, payload)
    yield put(turnOffAPISuccess(request))
  } catch (error) {
    yield put(turnOffAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* turnOff() {
  yield takeEvery(MessageActionType.TURN_OFF_API, turnOffAPI)
}

const turnOnBoxRequest = async (data) => API_BOX.post(`leds/turn-on`, data)

function* turnOnAPI({ payload }) {
  try {
    const request = yield call(turnOnBoxRequest, payload)
    yield put(turnOnAPISuccess(request))
  } catch (error) {
    yield put(turnOnAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* turnOn() {
  yield takeEvery(MessageActionType.TURN_ON_API, turnOnAPI)
}

const contactMeRequest = async (data) => API.post(`messages/${data.id}/contact-me`)
function* contactMeAPI({ payload }) {
  try {
    const request = yield call(contactMeRequest, payload)
    yield put(contactMeMessageAPISuccess(request))
  } catch (error) {
    yield put(contactMeMessageAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* contactMe() {
  yield takeEvery(MessageActionType.CONTACT_ME, contactMeAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getInvite),
    fork(getEvent),
    fork(getMissedNotification),
    fork(updateStatusMessage),
    fork(getResponseOfInvite),
    fork(turnOff),
    fork(turnOn),
    fork(contactMe),
  ])
}
