import { MemberActionType } from './../../types'
import moment from "moment"

const defaultState = {
  invitations: [],
  successMessage: null,
  errorMessage: null,
  loading: false,
  events: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case MemberActionType.GET_MEMBER_INVITATIONS_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.GET_MEMBER_INVITATIONS_API_SUCCESS:
      const invitations = []
      const { eventInvitations, notificationInvitations } = action.payload.data
      const eventsAlreadyAddedInCampaign = []
      const notificationScanned = []
      eventInvitations.forEach(inv => {
        invitations.push(inv)
        eventsAlreadyAddedInCampaign.push(inv.event_id)
      })

      notificationInvitations
          .filter(inv => eventsAlreadyAddedInCampaign.indexOf(inv.event_id) === -1)
          .sort((inv1, inv2) => {
              if (moment(inv1.created_at).isSame(inv2.created_at)) {
                return 0
              } else {
                return moment(inv1.created_at).isAfter(inv2.created_at) ? -1 : 1
              }
          })
          .forEach(inv => {
            if (notificationScanned.indexOf(inv.event_id) === -1) {
              invitations.push(inv)
              notificationScanned.push(inv.event_id)
            } else {
              console.log(`Skipping old notification ${inv.id} for event id ${inv.event_id}.` )
            }
          })
      return {
        ...state,
        loading: false,
        invitations,
      }

    case MemberActionType.GET_MEMBER_INVITATIONS_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.UPDATE_MEMBER_INVITATION_LIST:
      return { ...state, invitations: action.payload }

    case MemberActionType.UPDATE_MEMBER_EVENTS_LIST:
      return { ...state, events: action.payload }

    // TODO Update MEMBER_EVENTS Actions as events are being handled by the COMING_UP actions
    case MemberActionType.GET_MEMBER_EVENTS_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.GET_MEMBER_EVENTS_API_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.data,
      }

    case MemberActionType.GET_MEMBER_EVENTS_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.GET_COMING_UP_EVENTS_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.GET_COMING_UP_EVENTS_API_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.data,
      }

    case MemberActionType.GET_COMING_UP_EVENTS_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.POST_MEMBER_CONTACT_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.POST_MEMBER_CONTACT_API_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case MemberActionType.POST_MEMBER_CONTACT_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
