import React from 'react'
import PropTypes from 'prop-types'

const NoWifiIcon = ({ fillColor }) => {
  return (
    <svg
      enableBackground="new 0 0 479.056 479.056"
      height="30"
      viewBox="0 0 479.056 479.056"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      fill={fillColor}
    >
      <path
       d="m239.516 382.289-203.169-216.971c11.984-10.256 24.736-19.303 37.902-27.565l-21.724-21.723c-16.809 11.025-32.869 23.43-47.801 37.461-6.023 5.658-6.33 15.117-.673 21.14l224.559 239.806c2.822 3.026 6.784 4.737 10.921 4.737h.015c4.137 0 8.099-1.725 10.936-4.751l48.486-51.951-21.188-21.188s-38.264 41.005-38.264 41.005z"/><path d="m479.045 163.081c-.132-3.977-1.842-7.719-4.737-10.438-64.107-59.824-147.483-92.762-234.777-92.762-45.632 0-89.946 9.812-131.277 27.2l-57.142-57.141-21.169 21.169 398.007 398.007 21.169-21.169-109.275-109.275 135.196-144.859c2.705-2.909 4.152-6.769 4.006-10.731zm-160.389 134.402-187.448-187.448c34.431-12.916 70.889-20.214 108.323-20.214 74.692 0 146.27 26.432 203.155 74.75z"
        fill={fillColor}
      />
    </svg>
  )
}

NoWifiIcon.propTypes = {
  fill: PropTypes.string,
}

NoWifiIcon.defaultProps = {
  fill: "#707070",
}

export {NoWifiIcon}
