import {
  all,
} from 'redux-saga/effects'

import authSagas from './api/auth'
import memberSagas from './api/member'
import messageSagas from './api/message'
import splashSagas from './api/splash'

export default function* rootSaga() {
  yield all([
    authSagas(),
    memberSagas(),
    messageSagas(),
    splashSagas(),
  ])
}
