import Ws from '@adonisjs/websocket-client'
import {APIWS} from './api'

export class SocketConnection {

  constructor(){
    this.ws = Ws(APIWS, {reconnectionAttempts: 100})
  }
  connect(token) {
    
    this.ws = Ws(APIWS)
      //.withApiToken(token)
      .connect()

    this.ws.on(
      'open',
      () => {
        //console.log('Connection initialized');
      },
    )

    this.ws.on(
      'close',
      reason => {
        if(reason === 1006) {
          console.warn("Connection has been ended by the server")
  
          const reconnect = setInterval(() => {
            console.info("Reconnecting...")
  
            this.connect().then(() => {
              console.info("Connection has been restored.")
  
              if(this.ws.readyState === this.ws.OPEN) {
                clearInterval(reconnect)
              }
              
            }).catch(error => {
              console.error(error)
            })
          }, 5000)
        }
      },
    )

    if(token){}

    return this
  }

  subscribe(channel, handler) {
    //console.log("chanell", channel, "handler", handler)
    if (!this.ws) {
      setTimeout(() => this.subscribe(channel, null), 1000)
    } else {
      const result = this.ws.subscribe(channel)
      
      result.on(
        'message',
        (message) => {
          handler(message)
        },
      )

      result.on(
        'error',
        (error) => {
          console.error(error)
        },
      )

      return result
    }
  }
}