import React from 'react'
import PropTypes from 'prop-types'

const CloseIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
      <g id="Group_5" data-name="Group 5" transform="translate(-32.732 -247.732)">
        <line id="Line_4" data-name="Line 4" x1="13.965" y2="13.965" transform="translate(34.5 249.5)" fill="none" stroke={fill} strokeWidth="5"/>
        <line id="Line_5" data-name="Line 5" x1="13.965" y1="13.965" transform="translate(34.5 249.5)" fill="none" stroke={fill} strokeWidth="5"/>
      </g>
    </svg>
  )
}

CloseIcon.propTypes = {
  fill: PropTypes.string,
}

CloseIcon.defaultProps = {
  fill: "#FFF",
}

export {CloseIcon}
