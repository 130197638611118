import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { LayoutBox, Title, ParallaxImage } from './../../components'
import { getInviteAPI } from '../../effects/actions'
import ButtonComingUpComponent from "../../components/button/coming-up"

const TextDiv = styled.div`
  padding: 8px;
  background: #262626;
  height: 32vh;
  color: #FFF;
  overflow-y: scroll;
  font-size: 15px;
  font-family: "Helvetica Neue LT W05 55 Roman";
  &::-webkit-scrollbar {
    display: none;
  }
`

const EventLearnMorePage = ({ event, getInviteAPI, token }) => {
    const { id } = useParams()
    useEffect(() => {
        if (token && !event ){
            getInviteAPI(id)
        }
    }, [token, event, getInviteAPI, id])


    if(event !== null){
        let title = event && event.headline,
        location = event && event.location,
        description = event && event.description,
        image = event && event.media_detail

        if (!image) {
            image = event && event.media
        }

        return (
            <LayoutBox showHeader styles={{ marginTop: "7vh"}} showBack showClose>
                <Title margin="0 0 1px 0" fontSize={"32px"}>
                    {title}
                </Title>
                <Row>
                    {image && <Col xl={8} lg={8} sm={8} md={8} xs={8} style={{ padding: 8 }}>
                        <ParallaxImage
                            height={"32vh"}
                            image={image}
                        />
                    </Col>}
                    <Col xl={16} lg={16} sm={16} md={16} xs={16} style={{ padding: 8 }}>
                        <TextDiv>
                            {event.subheadline} in {location}.
                            <br/>
                            {description}
                        </TextDiv>
                    </Col>
                </Row>
                <ButtonComingUpComponent
                    id={id}
                    showLearnMore={false}
                />
            </LayoutBox>
        )
    }
    return <LayoutBox showHeader contentWithPadding={false} loading={true} />
}


EventLearnMorePage.propTypes = {
    event: PropTypes.object,
    token: PropTypes.string,
    getInviteAPI: PropTypes.func,
}

const mapStateToProps = ({ messageAPI, authAPI }) => ({
    token: authAPI.token, event: messageAPI.event,
})

export default connect(mapStateToProps, {
    getInviteAPI,
})(EventLearnMorePage)
