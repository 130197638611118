import {LOCAL_BOX_URL} from './urls'

export const menuItems = [
    {
        hasSubItems: false,
        path: '/invitations',
        title: 'Invitations',
        breadcrumbName: 'Invitations',
    },
    {
        hasSubItems: false,
        path: '/coming-up',
        title: "What's coming up",
        breadcrumbName: "What's coming up",
    },    
    {
        hasSubItems: false,
        path: '/contact-me',
        title: 'Contact me',
        breadcrumbName: 'Contact me',
    },
    {
        hasSubItems: false,
        path: `${LOCAL_BOX_URL}/?issetup=true`,
        external: true, 
        title: 'WiFi setup',
        breadcrumbName: 'WiFi setup',
    },
]
