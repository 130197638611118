import { REHYDRATE } from 'redux-persist'
import { AuthActionType } from './../../types'

const defaultState = {
  loggedUser: null,
  validToken: false,
  successMessage: null,
  errorMessage: null,
  loading: false,
  token: null,
  queriedMacAddress: null,
  welcomeVideoViewed: false,
  loadingWelcomeMessage: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case REHYDRATE:
      return {
        ...state,
        token:
                action.payload !== undefined
                  ? action.payload.authAPI !== undefined ? action.payload.authAPI.token : defaultState.token
                  : defaultState.token,
        loggedUser:
                action.payload !== undefined
                  ? action.payload.authAPI !== undefined ? action.payload.authAPI.loggedUser : defaultState.loggedUser
                  : defaultState.loggedUser,
        welcomeVideoViewed:
            action.payload !== undefined
                ? action.payload.authAPI !== undefined ? action.payload.authAPI.welcomeVideoViewed : defaultState.welcomeVideoViewed
                : defaultState.welcomeVideoViewed,
      }

    case AuthActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case AuthActionType.SIGN_IN_API:
      return {
        ...state,
        loading: true,
      }

    case AuthActionType.SIGN_IN_API_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedUser: action.payload.data.user,
        token: action.payload.data.access_token.token,
        successMessage: 'success',
      }

    case AuthActionType.SIGN_IN_API_FAILURE:
      return {
        ...defaultState,
        loading: false,
        errorMessage: action.payload.data,
      }

    case AuthActionType.CHANGE_SIGN_IN_INPUT_VALUE:
      return { ...state, signInInputs: { ...state.signInInputs, [action.payload.property]: action.payload.value } }


    default: return { ...state }
  }
}
