import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from './../../'
import {contactMeMessageAPI} from './../../../effects/actions'

const GroupStyled = styled.div`
    display: inline-flex;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 24px;
    justify-content: center;
`

const ButtonInviteComponent = ({
  onConfirmImIn, onConfirmIWontGo, onLearnMore, showLearnMore,
  showBack, willGoButtonText, willGoButtonColor, willGoButtonBackground,
  wontGoButtonText, wontGoButtonColor, wontGoButtonBackground,
  showContact, showAction1, showAction2, onContactMe, id, contactMeMessageAPI,
}) => {
  const history = useHistory()

  const handleContactMe = () => {
    contactMeMessageAPI({
      id,
    })
    onContactMe()
    history.push(`/invite/${id}/contact-me`)
  }

  return (
    <GroupStyled>
      {showAction1 && <Button label={willGoButtonText} style={{ width: 150 }} onClick={() => { onConfirmImIn() }} color={willGoButtonColor} backgroundcolor={willGoButtonBackground} />}
      {showAction2 && <Button label={wontGoButtonText} style={{ width: 150 }} onClick={() => { onConfirmIWontGo() }} color={wontGoButtonColor} backgroundcolor={wontGoButtonBackground} />}
      {showContact && <Button label="Contact Me"  style={{ width: 150 }}  onClick={handleContactMe} />}
      {showLearnMore && <Button label="Learn More" style={{ width: 150 }}  onClick={() => { onLearnMore()}} />}
      {showBack && <Button label="Back" style={{ width: 150 }}  onClick={() => history.goBack()} />}
   </GroupStyled>
  )
}

ButtonInviteComponent.propTypes = {
  onConfirmImIn: PropTypes.func,
  status: PropTypes.object,
  statusId: PropTypes.number,
  onConfirmIWontGo: PropTypes.func,
  onLearnMore: PropTypes.func,
  showLearnMore: PropTypes.bool,
  showBack: PropTypes.bool,
  showContact: PropTypes.bool,
  willGoButtonText: PropTypes.string,
  willGoButtonColor: PropTypes.string,
  willGoButtonBackground: PropTypes.string,
  wontGoButtonText: PropTypes.string,
  wontGoButtonColor: PropTypes.string,
  wontGoButtonBackground: PropTypes.string,
  onContactMe: PropTypes.func,
  id: PropTypes.any,
}

ButtonInviteComponent.defaultProps = {
  onConfirmImIn: () => {},
  onConfirmIWontGo: () => {},
  onLearnMore: () => {},
  onContactMe: () => {},
  showLearnMore: true,
  showBack: false,
  willGoButtonText: "I will go",
  willGoButtonColor: "#FFF",
  willGoButtonBackground: "#696969",
  wontGoButtonText: "I won't go",
  wontGoButtonColor: "#FFF",
  wontGoButtonBackground: "#FF4500",
  showContact: false,
  showAction1: false,
  showAction2: false,
}

export default connect(null, {
  contactMeMessageAPI,
})(ButtonInviteComponent)
