import { AuthActionType } from './../../types'

export const signInAPISuccess = (response) => ({
  type: AuthActionType.SIGN_IN_API_SUCCESS,
  payload: response,
})

export const signInAPIFailure = (
  error,
) => ({
  type: AuthActionType.SIGN_IN_API_FAILURE,
  payload: error,
})

export const signInAPI = (
  macAddress,
) => ({
  type: AuthActionType.SIGN_IN_API,
  payload: {macAddress},
})

export const changeSignInInputValue = (
  property, value,
) => ({
  type: AuthActionType.CHANGE_SIGN_IN_INPUT_VALUE,
  payload: { property, value },
})

export const setAuthPropertyValue = (
  property, value,
) => ({
  type: AuthActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const signOutAPI = (
) => ({
  type: AuthActionType.SIGN_OUT_API,
})
