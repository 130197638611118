import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Carousel, CenterContent, LayoutBox, Title} from './../../components'
import {getComingUpEventsAPI} from './../../effects/actions'
import ComingUpCardComponent from "../../components/card/coming-up"

const CalendarContent = styled.div`
  margin-top: 0%;
  padding: 3%;
`

const WhatsComingUpPage = ({ events, getComingUpEventsAPI, loggedUser }) => {
  useEffect(() => {
    if(loggedUser) {
      getComingUpEventsAPI()
    }
  }, [getComingUpEventsAPI, loggedUser])

  const renderCarrousel = () => {
    return (
      <CenterContent>
        <Carousel>
          {events.map((event) => (<ComingUpCardComponent key={event.id} event={event} />) )}
        </Carousel>
      </CenterContent>
    )
  }

  const renderNoContent = () => {
    return (
      <CenterContent>
        <Title textalign={"center"}>No events coming up yet</Title>
      </CenterContent>
    )
  }
  return (
    <LayoutBox showHeader showClose>
      <CalendarContent>
        {
          events.length > 0 ? renderCarrousel() : renderNoContent()
        }
      </CalendarContent>
    </LayoutBox>
  )
}

WhatsComingUpPage.propTypes = {
  getComingUpEventsAPI: PropTypes.func,
  events: PropTypes.array,
  loggedUser: PropTypes.string,
}

const mapStateToProps = ({ authAPI, memberAPI }) => {
  const {
    loggedUser,
  } = authAPI
  const {
    events,
  } = memberAPI
  return {
    loggedUser, events,
  }
}

export default
connect(mapStateToProps, {
  getComingUpEventsAPI,
})(WhatsComingUpPage)
