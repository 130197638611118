import { MessageActionType } from './../../types'

export const getInviteAPISuccess = (response) => ({
  type: MessageActionType.GET_INVITE_API_SUCCESS,
  payload: response,
})

export const getInviteAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_INVITE_API_FAILURE,
  payload: error,
})

export const getInviteAPI = (id) => ({
  type: MessageActionType.GET_INVITE_API,
  payload: id,
})

export const getEventAPISuccess = (response) => ({
  type: MessageActionType.GET_EVENT_API_SUCCESS,
  payload: response,
})

export const getEventAPIFailure = (error) => ({
  type: MessageActionType.GET_EVENT_API_FAILURE,
  payload: error,
})

export const getEventAPI = (id) => ({
  type: MessageActionType.GET_EVENT_API,
  payload: id,
})

export const updateStatusMessageAPISuccess = (response) => ({
  type: MessageActionType.UPDATE_STATUS_MESSAGE_API_SUCCESS,
  payload: response,
})

export const updateStatusMessageAPIFailure = (
  error,
) => ({
  type: MessageActionType.UPDATE_STATUS_MESSAGE_API_FAILURE,
  payload: error,
})

export const updateStatusMessageAPI = (messageId,statusId) => ({
  type: MessageActionType.UPDATE_STATUS_MESSAGE_API,
  payload: {messageId, statusId},
})

export const contactMeMessageAPISuccess = (response) => ({
  type: MessageActionType.CONTACT_ME_SUCCESS,
  payload: response,
})

export const contactMeMessageAPIFailure = (
    error,
) => ({
  type: MessageActionType.CONTACT_ME_FAILURE,
  payload: error,
})

export const contactMeMessageAPI = (messageId) => ({
  type: MessageActionType.CONTACT_ME,
  payload: messageId,
})

export const setMessagePropertyValue = (
  property, value,
) => ({
  type: MessageActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const getResponseOfInviteAPISuccess = (response) => ({
  type: MessageActionType.GET_RESPONSE_OF_INVITE_API_SUCCESS,
  payload: response,
})

export const getResponseOfInviteAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_RESPONSE_OF_INVITE_API_FAILURE,
  payload: error,
})

export const getResponseOfInviteAPI = (id) => ({
  type: MessageActionType.GET_RESPONSE_OF_INVITE_API,
  payload: id,
})

export const setMessageInviteOriginalPropertyValue = () => ({
  type: MessageActionType.SET_MESSAGE_INVITE_ORIGINAL_PROPERTY_VALUE,
})


export const turnOffAPISuccess = (response) => ({
  type: MessageActionType.TURN_OFF_API_SUCCESS,
  payload: response,
})

export const turnOffAPIFailure = (
  error,
) => ({
  type: MessageActionType.TURN_OFF_API_FAILURE,
  payload: error,
})

export const turnOffAPI = (payload) => ({
  type: MessageActionType.TURN_OFF_API,
  payload,
})

export const turnOnAPISuccess = (response) => ({
  type: MessageActionType.TURN_ON_API_SUCCESS,
  payload: response,
})

export const turnOnAPIFailure = (
    error,
) => ({
  type: MessageActionType.TURN_ON_API_FAILURE,
  payload: error,
})

export const turnOnAPI = (payload) => ({
  type: MessageActionType.TURN_ON_API,
  payload,
})

export const getMissedNotificationsSuccess = (response) => ({
  type: MessageActionType.GET_MISSED_NOTIFICATIONS_API_SUCCESS,
  payload: response,
})

export const getMissedNotificationsFailure = (
    error,
) => ({
  type: MessageActionType.GET_MISSED_NOTIFICATIONS_API_FAILURE,
  payload: error,
})

export const getMissedNotifications = (payload) => ({
  type: MessageActionType.GET_MISSED_NOTIFICATIONS_API,
  payload,
})
