import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ContainerStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: ${(props) => props.padding}px;
`

const ContainerComponent = ({ children, ...props }) => {
  return (
    <ContainerStyled {...props}>
      {children}
    </ContainerStyled>
  )
}

ContainerComponent.propTypes = {
  children: PropTypes.any,
  padding: PropTypes.number,
}

ContainerComponent.defaultProps = {
  padding: 16,
}

export default ContainerComponent
