import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LayoutBox, Carousel, CardInvite, CenterContent, Title } from './../../components'
import {
  getMemberInvitationsAPI,
  setMessageInviteOriginalPropertyValue,
} from './../../effects/actions'
import { useEffect } from 'react'

const CalendarContent = styled.div`
  margin-top: 0;
  padding: 3%;
`

const InvitationsPage = ({ loggedUser, invitations, setMessageInviteOriginalPropertyValue, getMemberInvitationsAPI }) => {

  useEffect(() => {
    if (loggedUser) {
      console.log('Calling from invitation pages')
      getMemberInvitationsAPI()
    }
    setMessageInviteOriginalPropertyValue()
  }, [setMessageInviteOriginalPropertyValue, getMemberInvitationsAPI, loggedUser])
  const renderCarrousel = () => {
    return (
      <CenterContent>
        <Carousel>
          {
            invitations.map((invitation, key) => {
              if (invitation.message && invitation.message.campaign) {
                return (
                    <CardInvite
                        key={key}
                        invite={invitation}
                    />
                )
              } else {
                return null
              }
          })}
        </Carousel>
      </CenterContent>
    )
  }

  const renderNoContent = () => {
    return (
      <CenterContent>
        <Title textalign={"center"}>No invitations yet</Title>
      </CenterContent>
    )
  }

  return (
    <LayoutBox showHeader showClose>
      <CalendarContent>
        {
          invitations.length > 0 ? renderCarrousel() : renderNoContent()
        }
      </CalendarContent>
    </LayoutBox>
  )
}

InvitationsPage.propTypes = {
  loggedUser: PropTypes.string,
  getMemberInvitationsAPI: PropTypes.func,
  setMessageInviteOriginalPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ authAPI, memberAPI }) => {
  const {
    loggedUser,
  } = authAPI
  const {
    invitations,
  } = memberAPI
  return {
    loggedUser, invitations,
  }
}

export default
connect(mapStateToProps, {
  getMemberInvitationsAPI,
  setMessageInviteOriginalPropertyValue,
})(InvitationsPage)
