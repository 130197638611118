export const Util = {
  renderMessage: (message) => {
    switch (message) {
      case 'internal_error':
        return 'Internal error, try again.'

      case 'email_or_password_invalid':
        return 'Invalid email or password.'

      case 'email_invalid':
        return 'Invalid email.'

      case 'password_invalid':
        return 'Invalid password.'

      case 'error.user_exists':
        return 'Email already exists.'

      default:
        return 'Internal error, try again.'
    }
  },
  getErrorLogin(error) {
    const errorResponse = error
    errorResponse.data = 'internal_error'
    if (error.data !== undefined) {
      if (error.data.uidField === undefined) {
        errorResponse.data = 'email_invalid'
      } else {
        errorResponse.data = 'password_invalid'
      }
    }
    return errorResponse
  },
  getError(error) {
    const errorResponse = error
    errorResponse.response.data = 'internal_error'
    return errorResponse
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
  getExtension(fname) {
    if (fname) {
      let name = typeof fname !== 'string' ? fname.name : fname
      var pos = name.lastIndexOf(".")
      var strlen = name.length
      if (pos !== -1 && strlen !== pos + 1) {
        var ext = name.split(".")
        var len = ext.length
        var extension = ext[len - 1].toLowerCase()
      } else {
        extension = null
      }
    } else {
      extension = null
    }
    return extension
  },
  getFileType(file){
    const extension = this.getExtension(file)
    if(extension !== null){
      if(['mp4', 'mpeg'].includes(extension)){
        return 'video'
      }else if(['jpg', 'png', 'jpeg'].includes(extension)){
        return 'image'
      }
    }
  },

}
