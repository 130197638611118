import React from 'react'
import {Redirect, Route} from "react-router-dom"

export const PrivateRoute = ({children, isLoggedIn, ...rest}) => {
    let pathname = '/unavailable'
    if (rest.location.state !== undefined && rest.location.state.from !== undefined) pathname = rest.location.state.from.pathname
    return (
        <Route
            {...rest}
            render={({ location }) => isLoggedIn ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname,
                        state: { from: location },
                    }}
                />
            )}
        />
    )
}
