import React from 'react'
import {CenterContent, Title} from './../../components'
import {LayoutBox} from "./../../components"
import {connect} from "react-redux"

const defaultMessage = "Box not active. Please call (xxx)xxx-xxxx"
const Unavailable = ({loggedUser}) => {
  return (
      <LayoutBox
          showHeader
          contentWithPadding={false}
      >
          <CenterContent>
            <Title margin="2% 0 2% 0" fontSize={"32px"}>
                {loggedUser && loggedUser.deactivated_message ? loggedUser.deactivated_message : defaultMessage}
            </Title>
          </CenterContent>
      </LayoutBox>
  )
}

Unavailable.propTypes = {
}

const mapStateToProps = ({ authAPI }) => {
    const {
        loggedUser,
    } = authAPI
    return {
        loggedUser,
    }
}
export default connect(mapStateToProps)(Unavailable)
