/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState }  from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { setAuthPropertyValue } from './../../effects/actions'
import {LayoutBox, ParallaxImage, ParallaxVideo, Title} from './../../components'
import { useHistory } from 'react-router'
import { Util } from '../../utils'

const MessageContent = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HomeScreenPage = ({ loggedUser, setAuthPropertyValue, welcomeVideoViewed }) => {
  const history = useHistory()
  const [showWelcomeVideo, setShowWelcomeVideo] = useState(true)
  useEffect(() => {
    if(loggedUser){
      if(loggedUser.box && welcomeVideoViewed === false){
        setShowWelcomeVideo(true)
      } else {
        history.push('/splash')
      }
    }
  },[loggedUser])

  useEffect(() => {
    if(loggedUser){
      if(showWelcomeVideo && welcomeVideoViewed === false){
        const videoElement = document.getElementById("welcomeVideo")
        const imageElement = document.getElementById("welcomeImage")
        if (videoElement) {
          videoElement.onloadedmetadata = function () {
            this.muted = true
            this.play()
            setTimeout(() => {
              setAuthPropertyValue('welcomeVideoViewed', true)
            }, loggedUser.message_length)
          }
        } else if (imageElement) {
          imageElement.onload = function () {
            setTimeout(() => {
              setAuthPropertyValue('welcomeVideoViewed', true)
            }, loggedUser.message_length)
          }
        }
      }else{
        history.push('/splash')
      }
    }
  }, [loggedUser, welcomeVideoViewed, showWelcomeVideo, setAuthPropertyValue])

  const renderMedia = (media) => {
    const mediaType = Util.getFileType(media)
    if (mediaType) {
      return mediaType === "image" ?
          <ParallaxImage
              height={"100vh"}
              image={media}
              imageId={"welcomeImage"}
          />
          :
          <ParallaxVideo
              id={"welcomeVideo"}
              height={"100vh"}
              video={media}
          />
    }
  }

  return (
    <LayoutBox showClose onClose={ () => setAuthPropertyValue('welcomeVideoViewed', true)} contentWithPadding={!(showWelcomeVideo && loggedUser !== null && welcomeVideoViewed === false)} showHeader>
      {(showWelcomeVideo && loggedUser !== null && welcomeVideoViewed === false) && (
        <>
          {
            loggedUser.message_media ? renderMedia(loggedUser.message_media) : null
          }
          <MessageContent>
            <Title>{loggedUser.message}</Title>
          </MessageContent>
        </>
      )}

    </LayoutBox>
  )
}

HomeScreenPage.propTypes = {
  loggedUser: PropTypes.object,
  welcomeVideoViewed: PropTypes.bool,
  setAuthPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ authAPI }) => {
  const {
    loggedUser, welcomeVideoViewed,
  } = authAPI
  return {
    loggedUser, welcomeVideoViewed,
  }
}

export default connect(mapStateToProps, {
  setAuthPropertyValue,
})(HomeScreenPage)
