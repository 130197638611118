/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import jwt_decode from "jwt-decode"
import {
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { Layout, Spin } from 'antd'
import { useEffect } from 'react'
import styled from 'styled-components'
import {SocketConnection} from './utils/socket'

import {
  ContactMePage,
  InvitePage,
  InvitationsPage,
  SplashScreenPage,
  WhatsComingUpPage,
  LearnMorePage,
  HomeScreenPage,
  InviteConfirmPage, Unavailable,
} from './pages'
import {
  signInAPI,
  setAuthPropertyValue,
  getMemberInvitationsAPI, getSplashesAPI, turnOnAPI, getMissedNotifications,
} from './effects/actions'
import { useState } from 'react'
import ComingUpPage from "./pages/coming-up";
import EventLearnMorePage from "./pages/coming-up-learn-more";
import InviteContactMe from "./pages/invite-contact-me";
import {PrivateRoute} from "./privateRoute";
import {PublicRoute} from "./publicRoute";

const Loader = styled(Spin)`
    background: #000;
    color: #fff;
    max-height: 100vh !important;
`

const App = ({
  loggedUser, signInAPI, loading, macAddress, queriedMacAddress, setAuthPropertyValue, token, getMemberInvitationsAPI, getSplashesAPI, turnOnAPI, getMissedNotifications,
}) => {
  const isAuthorized = !!loggedUser && loggedUser.is_activated
  const location = useLocation()
  const history = useHistory()
  const [lastScreen, setLastScreen] = useState('/')
  const [socketSubscribed, setSocketSubscribed] = useState(false);
  const websocket = new SocketConnection()
  let connection = websocket.connect(token)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkNotificationType = (data) => {
    history.push(`/invite/${data.id}?is_notification=true`)
    turnOnAPI(data.notificationData);
  }

  const handleSplashChange = (data) => {
    // Maintain the current playing splash sequence and insert it in right place
    if (loggedUser) {
      getSplashesAPI(loggedUser.id)
    }
  }

  const handleActivatedStatusChanged = () => {
    signInAPI(loggedUser.box.mac_address)
  }

  useEffect(() => {
    const search = location.search;
    const queriedMacAddress = new URLSearchParams(search).get("macAddress");
    if (queriedMacAddress) {
      setAuthPropertyValue('queriedMacAddress', queriedMacAddress)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnIdle = () => {
    if(location.pathname !== "/splash"){
      setLastScreen(location.pathname)
      history.push("/splash")
    }
  }

  const handleOnActive = () => {
    if(location.pathname === "/splash"){
      history.push(lastScreen)
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15, //15 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  useEffect(() => {
    // If new macAddress is queried and its different from the current one attached to the user, call sign in the user with updated macAddress
    if (queriedMacAddress && macAddress !== queriedMacAddress) {
      signInAPI(queriedMacAddress);
    }
    if (macAddress && isAuthorized) {
      getMissedNotifications(macAddress)
    }
  }, [loggedUser, signInAPI, macAddress, queriedMacAddress, isAuthorized, getMissedNotifications])

  useEffect(() => {
    if(token && connection && !socketSubscribed){
      socketSubscription()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, connection])

  useEffect(() => {
    if (token) {
      getMemberInvitationsAPI()
    }
  }, [token, getMemberInvitationsAPI])

  const socketSubscription = () => {
    let tokenDecrypt = jwt_decode(token)
    let memberId = tokenDecrypt.data && tokenDecrypt.data.memberId
    connection.subscribe(
        `member:listInvitationOfMemberId=${memberId}`,
        ({data}) => {
          checkNotificationType(data)
        },
    )
    connection.subscribe(
        `member:splash-update-event`,
        ({data}) => {
         // Load the splash message if its all the users or if the splash changed is for this current user only
         if (!data.member_id || +data.member_id === +memberId) {
           handleSplashChange(data);
         }
        },
    )
    connection.subscribe(
          `member:activated-status-changed-${memberId}`,
        ({data}) => {
          handleActivatedStatusChanged(data);
        }
    )
    setSocketSubscribed(true)
  }

  return (
    <Loader tip="Loading..." spinning={loading} >
      <Layout>
        <Switch>
          <PublicRoute path="/unavailable" isLoggedIn={isAuthorized}>
            <Unavailable />
          </PublicRoute>
          <PrivateRoute path="/invite/:id/contact-me" isLoggedIn={isAuthorized}>
            <InviteContactMe />
          </PrivateRoute>
          <PrivateRoute path="/invite/:id/confirm" isLoggedIn={isAuthorized}>
            <InviteConfirmPage />
          </PrivateRoute>
          <PrivateRoute path="/invite/:id/learn-more" isLoggedIn={isAuthorized}>
            <LearnMorePage />
          </PrivateRoute>
          <PrivateRoute path="/invite/:id" isLoggedIn={isAuthorized}>
            <InvitePage />
          </PrivateRoute>
          <PrivateRoute path="/coming-up/:id/learn-more" isLoggedIn={isAuthorized}>
            <EventLearnMorePage />
          </PrivateRoute>
          <PrivateRoute path="/coming-up/:id" isLoggedIn={isAuthorized}>
            <ComingUpPage />
          </PrivateRoute>
          <PrivateRoute exact path="/coming-up" isLoggedIn={isAuthorized}>
            <WhatsComingUpPage />
          </PrivateRoute>
          <PrivateRoute path="/splash" isLoggedIn={isAuthorized}>
            <SplashScreenPage />
          </PrivateRoute>
          <PrivateRoute path="/invitations" isLoggedIn={isAuthorized}>
            <InvitationsPage />
          </PrivateRoute>
          <PrivateRoute path="/contact-me" isLoggedIn={isAuthorized}>
            <ContactMePage />
          </PrivateRoute>
          <PrivateRoute path="/" isLoggedIn={isAuthorized}>
            <HomeScreenPage />
          </PrivateRoute>
        </Switch>
      </Layout>
    </Loader>
  )
}

App.propTypes = {
  loggedUser: PropTypes.object,
  signInAPI: PropTypes.func,
  loading: PropTypes.bool,
  macAddress: PropTypes.string,
  setAuthPropertyValue: PropTypes.func,
  loadingMessage: PropTypes.bool,
  token: PropTypes.string,
  getMemberInvitationsAPI: PropTypes.func,
}

const mapStateToProps = ({ authAPI, messageAPI }) => {
  const {
    loggedUser, loading, token, queriedMacAddress,
  } = authAPI
  const loadingMessage = messageAPI.loading
  const macAddress = loggedUser && loggedUser.box ? loggedUser.box.mac_address : null;
  return {
    loggedUser, loading, macAddress, loadingMessage, token, queriedMacAddress,
  }
}

export default
connect(mapStateToProps, {
  signInAPI,
  setAuthPropertyValue,
  getMemberInvitationsAPI,
  getSplashesAPI,
  turnOnAPI,
  getMissedNotifications,
})(App)
