import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled from 'styled-components'
import { LayoutBox, ParallaxImage, Title, ParallaxVideo } from '../../components'
import {getSplashesAPI, setMessagePropertyValue, turnOnAPI} from '../../effects/actions'
import {useHistory} from "react-router"

const MessageContent = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SplashScreenPage = ({ splashes, getSplashesAPI, loggedUser, missedNotifications, setMessagePropertyValue, turnOnAPI }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentSplash, setCurrentSplash] = useState(null)
  const [loading, setLoading] = useState(true)
  const [missedNotificationChecked, setMissedNotificationChecked] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (loggedUser) {
      getSplashesAPI(loggedUser.id)
    }
  }, [loggedUser, getSplashesAPI])

  useEffect(() => {
    if (missedNotifications && missedNotifications.length && !missedNotificationChecked) {
      setMissedNotificationChecked(true)
      setTimeout(() => {
          const allNotifications = [...missedNotifications]
          const latestNotification = allNotifications.pop()
          setMessagePropertyValue('missedNotifications', allNotifications)
          turnOnAPI(latestNotification.message.led_sequence)
          console.log(`/invite/${latestNotification.message.id}?is_notification=true`)
          history.push(`/invite/${latestNotification.message.id}?is_notification=true`)
        }, 1000)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missedNotifications])

  useEffect(() => {
    if (splashes.length) {
      if (splashes.length > currentIndex) {
        const nextSplash = splashes[currentIndex]
        setCurrentSplash(nextSplash)
      }
      // In case the current splash is deleted, updatedCurrentIndex will pick the next available one
      const adjustedCurrentIndex = currentIndex === splashes.length ? 0 : currentIndex
      const timeoutId = setTimeout(() => {
        const nextIndex = (adjustedCurrentIndex + 1) % splashes.length
        setCurrentIndex(nextIndex)
        if ( splashes[nextIndex].type === "video") {
          const video = document.getElementById("splashScreen")
          if (video) {
            video.onloadedmetadata = function () {
              this.muted = true
              this.play()
            }
          }
        }
      }, splashes[adjustedCurrentIndex].time_length)

      return () => {
        clearTimeout(timeoutId)
      }
    }

  }, [splashes, currentIndex])

  if (loading) {
    return <LayoutBox contentWithPadding={false} showHeader loading />
  }

  return (
    <LayoutBox contentWithPadding={false} showHeader iconColor={currentSplash ? currentSplash.icons_fill_color : '#ffffff'}>
      {!!currentSplash && (
        currentSplash.type === "image" ?
          <ParallaxImage
            height={"100vh"}
            image={currentSplash.media}
          />
          :
          <ParallaxVideo
            height={"100vh"}
            id={"splashScreen"}
            video={currentSplash.media}
          />
      )}
      {
        currentSplash && currentSplash.message && (
            <MessageContent><Title color={currentSplash.icons_fill_color}>{currentSplash.message}</Title></MessageContent>
        )
      }
    </LayoutBox>
  )
}

SplashScreenPage.propTypes = {
  splashes: PropTypes.array,
  getSplashesAPI: PropTypes.func,
  loggedUser: PropTypes.bool,
  missedNotifications: PropTypes.array,
}

const mapStateToProps = ({ splashAPI, authAPI, messageAPI }) => {
  const {
    splashes,
  } = splashAPI
  const {
    loggedUser,
  } = authAPI
  const {
    missedNotifications,
  } = messageAPI
  return {
    splashes, loggedUser, missedNotifications,
  }
}

export default
  connect(mapStateToProps, {
    getSplashesAPI,
    setMessagePropertyValue,
    turnOnAPI,
  })(SplashScreenPage)

