import { SplashActionType } from './../../types'

export const getSplashesAPISuccess = (response) => ({
  type: SplashActionType.GET_SPLASHES_API_SUCCESS,
  payload: response,
})

export const getSplashesAPIFailure = (
  error,
) => ({
  type: SplashActionType.GET_SPLASHES_API_FAILURE,
  payload: error,
})

export const getSplashesAPI = (id) => ({
  type: SplashActionType.GET_SPLASHES_API,
  payload: {id},
})
