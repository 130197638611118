import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Util } from '../../utils'

const ParallaxVideoStyled = styled.div`
    height: ${(props) => props.height} !important;
    width: 100%;
    video {
      min-width: 100%;
      position: fixed;
      top:0;
      height: 100vh;
    }
`

const ParallaxVideoComponent = ({ children, video, id, ...props }) => {
  const type = Util.getExtension(video)
  return (
    <ParallaxVideoStyled {...props}>
      <video key={video} autoPlay allow="autoplay" loop id={id}>
        <source src={video} type={`video/${type}`} />
        Your browser does not support the video tag.
      </video>
    </ParallaxVideoStyled>
  )
}

ParallaxVideoComponent.propTypes = {
  video: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
}

ParallaxVideoComponent.defaultProps = {
  video: null,
  height: "85vh",
  id: "#videoParallax",
}

export default ParallaxVideoComponent
