import { MemberActionType } from './../../types'

export const getMemberInvitationsAPISuccess = (response) => ({
  type: MemberActionType.GET_MEMBER_INVITATIONS_API_SUCCESS,
  payload: response,
})

export const getMemberInvitationsAPIFailure = (
  error,
) => ({
  type: MemberActionType.GET_MEMBER_INVITATIONS_API_FAILURE,
  payload: error,
})

export const getMemberInvitationsAPI = () => ({
  type: MemberActionType.GET_MEMBER_INVITATIONS_API,
})

export const updateMemberInvitationList = (
  data,
) => ({
  type: MemberActionType.UPDATE_MEMBER_INVITATION_LIST,
  payload: data,
})

export const updateMemberEventsList = (
  data,
) => ({
  type: MemberActionType.UPDATE_MEMBER_EVENTS_LIST,
  payload: data,
})

export const getMemberEventsAPISuccess = (response) => ({
  type: MemberActionType.GET_MEMBER_EVENTS_API_SUCCESS,
  payload: response,
})

export const getMemberEventsAPIFailure = (
  error,
) => ({
  type: MemberActionType.GET_MEMBER_EVENTS_API_FAILURE,
  payload: error,
})

export const getMemberEventsAPI = () => ({
  type: MemberActionType.GET_MEMBER_EVENTS_API,
})

export const getComingUpEventsAPISuccess = (response) => ({
  type: MemberActionType.GET_COMING_UP_EVENTS_API_SUCCESS,
  payload: response,
})

export const getComingUpEventsAPIFailure = (
    error,
) => ({
  type: MemberActionType.GET_COMING_UP_EVENTS_API_FAILURE,
  payload: error,
})

export const getComingUpEventsAPI = () => ({
  type: MemberActionType.GET_COMING_UP_EVENTS_API,
})


export const postMemberContactAPISuccess = (response) => ({
  type: MemberActionType.POST_MEMBER_CONTACT_API_SUCCESS,
  payload: response,
})

export const postMemberContactAPIFailure = (
  error,
) => ({
  type: MemberActionType.POST_MEMBER_CONTACT_API_FAILURE,
  payload: error,
})

export const postMemberContactAPI = () => ({
  type: MemberActionType.POST_MEMBER_CONTACT_API,
})
