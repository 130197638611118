import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'antd'

const TitleStyled = styled(Typography.Title)`
    color: ${(props) => props.color} !important;
    text-align: ${(props) => props.textalign} !important;
    padding: ${(props) => props.padding} !important;
    font-size: ${(props) => props.fontSize} !important;
    margin: ${(props) => props.margin} !important;
    font-family: '${(props) => props.fontFamily}' !important;
    width: 100%;
`

const TitleComponent = ({ children, ...props }) => {
  return (
    <TitleStyled {...props}>
      {children}
    </TitleStyled>
  )
}

TitleComponent.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  textalign: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  fontFamily: PropTypes.string,
}

TitleComponent.defaultProps = {
  color: '#FFF',
  textalign: 'center',
  padding: "0",
  margin: "0 0 19px 0",
  fontSize: '31px',
  fontFamily: 'Helvetica Neue LT W05_75 Bold',
}

export default TitleComponent
