import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ParallaxImageStyled = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
    width: 100%;
    & .image-cover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
`

const ParallaxImageComponent = ({ children, ...props }) => {
  return (
    <ParallaxImageStyled {...props}>
      <div className='image-cover'>
        <img src={props.image} alt={props.alt} id={props.imageId}/>
      </div>
      {children}
    </ParallaxImageStyled>
  )
}

ParallaxImageComponent.propTypes = {
  image: PropTypes.string,
  height: PropTypes.string,
}

ParallaxImageComponent.defaultProps = {
  image: null,
  height: "0",
}

export default ParallaxImageComponent
