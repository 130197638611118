import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {useParams, useHistory, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { LayoutBox, Title, ParallaxImage, ButtonInvite, ParallaxVideo } from './../../components'
import {
  getInviteAPI, updateStatusMessageAPI, setMessagePropertyValue,
  getResponseOfInviteAPI, turnOffAPI,
} from './../../effects/actions'
import { Util } from '../../utils'

const MessageContent = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const InvitePage = ({
  messageInvite, getInviteAPI, token, successUpdateStatus, setMessagePropertyValue,
  getResponseOfInviteAPI, updateStatusMessageAPI, status, statusId, turnOffAPI, turnedOffSuccess,
}) => {
  const [messageData, setMessageData] = useState(null)
  const { id } = useParams()
  const isNotificationPage = new URLSearchParams(useLocation().search).get('is_notification')
  const history = useHistory()

  useEffect(() => {
    if((token !== null && messageInvite === null )){
      getInviteAPI(id)
      getResponseOfInviteAPI(id)
    }else if(token !== null && messageInvite !== null && Number(messageInvite.id) !== Number(id)){
      getInviteAPI(id)
      getResponseOfInviteAPI(id)
    }
  }, [token, messageInvite, id, getInviteAPI, getResponseOfInviteAPI])

  useEffect(() => {
    if(successUpdateStatus !== null) {
      setMessagePropertyValue("successUpdateStatus", null)
      getInviteAPI(id)
      getResponseOfInviteAPI(id)
    }
  }, [getInviteAPI, getResponseOfInviteAPI, id, setMessagePropertyValue, successUpdateStatus])

  useEffect(() => {
    if(messageInvite !== messageData){
      setMessageData(messageInvite)
      if(messageInvite && messageInvite.type_id){
        if(messageInvite.type_id === "message" && messageInvite.template && messageInvite.template.media){
          const type = Util.getFileType(messageInvite.template.media) === "video"
          if(type){
            var video = document.getElementById("messageVideo")
            if(video !== null){
              video.onloadedmetadata = function() {
                this.muted = true
                this.play()
              }
            }
          }
        }
      }
    }
  }, [messageInvite, messageData])

  useEffect(() => {
    if (turnedOffSuccess) {
      setMessagePropertyValue('turnedOffSuccess', null)
      history.push('/splash')
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [setMessagePropertyValue, turnedOffSuccess])

  const onConfirm = () => {
    updateStatusMessageAPI(id, 3)
    history.push({ pathname: `/invite/${id}/confirm`, state: { message: messageInvite.action_one_confirmation_message, id }})
  }

  const onConfirmIWontGo = () => {
    updateStatusMessageAPI(id, 4)
    history.push({ pathname: `/invite/${id}/confirm`, state: { message: messageInvite.action_two_confirmation_message, id }})
  }

  const handleOnCloseEvent = () => {
    turnOffAPI(id)
  }

  const renderDate = (startDate, endDate) => {
    if(startDate !== null && endDate !== null){
      return  ` on ${startDate.format("MMM Do")}` + startDate !== endDate && ` - ${endDate.format("MMM Do")}`
    }
    return null
  }

  if(messageInvite !== null){
    let startDate =  moment()
    let endDate =  moment()
    let title = "Prepare for an amazing day."
    let typeEvent = "event"
    let media = null
    startDate = messageInvite.event && messageInvite.event.start_date && moment(messageInvite.event.start_date)
    endDate = messageInvite.event && messageInvite.event.end_date && moment(messageInvite.event.end_date)
    title = messageInvite.event && messageInvite.event.headline
    typeEvent = messageInvite && messageInvite.type_id
    media = messageInvite && messageInvite.event && messageInvite.event.media
    if(typeEvent === "message"){
      title = messageInvite && messageInvite.template && messageInvite.template.text
      media = messageInvite && messageInvite.template && messageInvite.template.media
    }

    const renderMedia = (media) => {
      return Util.getFileType(media) === "image" ?
      <ParallaxImage
        height={"100px"}
        image={media}
      />
    :
      <ParallaxVideo
        height={"100vh"}
        id={"messageVideo"}
        video={media}
      />

    }

    const renderButtonInvite = () => (
      <ButtonInvite
          id={id}
          onConfirmImIn={onConfirm}
          onConfirmIWontGo={onConfirmIWontGo}
          statusId={statusId}
          status={status}
          showAction1={messageInvite && messageInvite.action_one_show}
          showAction2={messageInvite && messageInvite.action_two_show}
          showLearnMore={messageInvite && messageInvite.show_learn_more}
          showContact={messageInvite && messageInvite.show_contact_me}
          willGoButtonText={messageInvite && messageInvite.action_one_text}
          willGoButtonColor={messageInvite && messageInvite.action_one_color}
          willGoButtonBackground={messageInvite && messageInvite.action_one_background}
          wontGoButtonText={messageInvite && messageInvite.action_two_text}
          wontGoButtonColor={messageInvite && messageInvite.action_two_color}
          wontGoButtonBackground={messageInvite && messageInvite.action_two_background}
          onLearnMore={() => history.push(`/invite/${id}/learn-more`)}
        />
    )

    const renderIsEvent = () => {
      return (
        <>
          <Title margin="2% 0 2% 0" fontSize={"32px"}>
            {title}
          </Title>
          {renderMedia(media)}
          {typeEvent === "event" &&
            <Title padding="1% 0 0 0"  margin="1% 0 0 0" fontSize={"22px"}>
              {messageInvite.event
                && messageInvite.event.subheadline
              }
              {messageInvite.event
                && ` in ${messageInvite.event.location}`
              }

              {renderDate(startDate, endDate)}

            </Title>
          }
          {renderButtonInvite()}
        </>
      )
    }

    const renderIsMessage = () => {
      return (
        <>
         {renderMedia(media)}
          <MessageContent>
            <Title margin="2% 0 2% 0" fontSize={"32px"}>
              {title}
            </Title>
            {renderButtonInvite()}
          </MessageContent>
        </>
      )
    }

    return (
      <LayoutBox
          showHeader
          showClose
          showBack={!isNotificationPage}
          onClose={handleOnCloseEvent}
      >
        {typeEvent === "message" ? renderIsMessage() : renderIsEvent()}
      </LayoutBox>
    )
  }
  return  <LayoutBox showHeader contentWithPadding={false} showBack loading />
}


InvitePage.propTypes = {
  messageInvite: PropTypes.object,
  token: PropTypes.string,
  getInviteAPI: PropTypes.func,
  successUpdateStatus: PropTypes.string,
  setMessagePropertyValue: PropTypes.func,
  getResponseOfInviteAPI: PropTypes.func,
  updateStatusMessageAPI: PropTypes.func,
  status: PropTypes.string,
  statusId: PropTypes.any,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ messageAPI, authAPI }) => {
  const {
    token,
  } = authAPI
  const {
    messageInvite, successUpdateStatus, status, statusId, loading, turnedOffLoading,  turnedOffSuccess,
  } = messageAPI
  return {
    token, messageInvite, successUpdateStatus, status, statusId, loading, turnedOffLoading, turnedOffSuccess,
  }
}

export default connect(mapStateToProps, {
  getInviteAPI,
  setMessagePropertyValue,
  getResponseOfInviteAPI,
  updateStatusMessageAPI,
  turnOffAPI,
})(InvitePage)
