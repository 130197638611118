import React from "react"

const LogoSvgComponent = ({fillColor}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 952 1080">
            <path d="M360.93,408.1a138.41,138.41,0,0,1,28.88,2.79,62.52,62.52,0,0,1,22.36,9.17A42.59,42.59,0,0,1,426.61,437q5.13,10.57,5.13,26.09,0,16.77-7.61,28t-22.52,18.32q20.5,5.91,30.59,20.65t10.1,35.56q0,16.77-6.52,29a56.58,56.58,0,0,1-17.55,20,77.92,77.92,0,0,1-25.16,11.49,113.59,113.59,0,0,1-29,3.73H256.57V408.1Zm-6.22,89.76q13.05,0,21.43-6.21t8.39-20.19q0-7.75-2.8-12.74a20.59,20.59,0,0,0-7.45-7.76,31.43,31.43,0,0,0-10.72-3.88A70.5,70.5,0,0,0,351,446H305.33v51.87Zm2.8,94.1a65.1,65.1,0,0,0,13.66-1.39,33.67,33.67,0,0,0,11.5-4.66,23.59,23.59,0,0,0,7.92-8.85q2.94-5.59,3-14.29,0-17.07-9.63-24.38t-25.47-7.3H305.33V592Z" style={{fill: `${fillColor}`}}/>
            <path d="M588.51,408.1V588.86H702.68v41H539.75V408.1Z" style={{fill: `${fillColor}`}}/>
            <rect x="482.45" y="386.93" width="10" height="271" style={{fill: `${fillColor}`}}/>
            <path d="M472.23,943c-229.39,0-416-186.62-416-416s186.61-416,416-416,416,186.62,416,416S701.61,943,472.23,943Zm0-771.71c-196.14,0-355.71,159.57-355.71,355.71S276.09,882.71,472.23,882.71,827.94,723.14,827.94,527,668.37,171.29,472.23,171.29Z" style={{fill: `${fillColor}`}}/>
        </svg>
    )
}

export default LogoSvgComponent
