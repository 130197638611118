import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {Button} from '../../'
import {connect} from "react-redux"
import {contactMeMessageAPI} from "../../../effects/actions"

const GroupStyled = styled.div`
    display: inline-flex;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 24px;
    justify-content: center;
`

const ButtonComingUpComponent = ({showLearnMore, onLearnMore,  onContactMe, id, contactMeMessageAPI}) => {
    const history = useHistory()

    const handleContactMe = () => {
        contactMeMessageAPI({
            id,
        })
        onContactMe()
        history.push(`/invite/${id}/contact-me`)
    }

    const handleLearnMore = () => {
        onLearnMore()
        history.push(`/coming-up/${id}/learn-more`)
    }

    return (
        <GroupStyled>
            <Button label="Contact Me"  style={{ width: 150 }}  onClick={handleContactMe} />
            {
                showLearnMore && <Button label="Learn More" style={{ width: 150 }}  onClick={handleLearnMore} />
            }
            <Button label="Back" style={{ width: 150 }}  onClick={() => history.goBack()} />
        </GroupStyled>
    )
}

ButtonComingUpComponent.propTypes = {
    onLearnMore: PropTypes.func,
    onContactMe: PropTypes.func,
    id: PropTypes.string,
    showLearnMore: PropTypes.bool,
}

ButtonComingUpComponent.defaultProps = {
    onLearnMore: () => {},
    onContactMe: () => {},
    showLearnMore: true,
}

export default connect(null, {
    contactMeMessageAPI,
})(ButtonComingUpComponent)
