import { combineReducers } from 'redux'
import authReducer from './api/auth'
import memberReducer from './api/member'
import messageReducer from './api/message'
import splashReducer from './api/splash'

export const rootReducers = combineReducers({
  authAPI: authReducer,
  memberAPI: memberReducer,
  messageAPI: messageReducer,
  splashAPI: splashReducer,
})
