import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderStyled = styled(Slider)`
  width: 90vw;
  .slick-prev, .slick-next {
    height: 40px !important;
  }
`

const CarouselComponent = ({ children, settings, style }) => {    
      const prevArrow = (
        <svg style={{ width: 40 }} xmlns="http://www.w3.org/2000/svg" width="24" height="47" viewBox="0 0 24 47">
          <path id="Polygon_2" data-name="Polygon 2" d="M23.5,0,47,16H0Z" transform="translate(0 47) rotate(-90)" fill="#696969"/>
        </svg>
      )
      const nextArrow = (
        <svg style={{ width: 40 }} xmlns="http://www.w3.org/2000/svg" width="16" height="47" viewBox="0 0 16 47">
          <path id="Polygon_1" data-name="Polygon 1" d="M23.5,0,47,16H0Z" transform="translate(16) rotate(90)" fill="#696969"/>
        </svg>
      )
      return (
        <SliderStyled {...settings} prevArrow={prevArrow} nextArrow={nextArrow} style={style}>
          {children}
        </SliderStyled>
      )
}

CarouselComponent.propTypes = {
    children: PropTypes.any,
    settings: PropTypes.object,
    style: PropTypes.object,
}
  
CarouselComponent.defaultProps = {
    settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
    },
    style: {},
}

export default CarouselComponent
