import axios from 'axios'

export const APIWS =  process.env.REACT_APP_WS_ENDPOINT

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 60000,
})

export const API_BOX = axios.create({
  baseURL: process.env.REACT_APP_BOX_ENDPOINT,
  timeout: 60000,
})
