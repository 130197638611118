import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getMemberInvitationsAPIFailure,
  getMemberInvitationsAPISuccess,
  getMemberEventsAPISuccess,
  getMemberEventsAPIFailure,
  postMemberContactAPISuccess,
  postMemberContactAPIFailure,
  getComingUpEventsAPISuccess,
  getComingUpEventsAPIFailure,
} from './../../actions'
import {
  MemberActionType,
} from './../../types'
import { API } from './../../../utils/api'
import { Util } from './../../../utils'

const getMemberInvitationsRequest = async () => API.get('members/invites-received')
function* getMemberInvitationsAPI() {
  try {
    const request = yield call(getMemberInvitationsRequest)
    yield put(getMemberInvitationsAPISuccess(request))
  } catch (error) {
    yield put(getMemberInvitationsAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getMemberInvitations() {
  yield takeEvery(MemberActionType.GET_MEMBER_INVITATIONS_API, getMemberInvitationsAPI)
}

const getMemberEventsRequest = async () => API.get('members/events-not-received')
function* getMemberEventsAPI() {
  try {
    const request = yield call(getMemberEventsRequest)
    yield put(getMemberEventsAPISuccess(request))
  } catch (error) {
    yield put(getMemberEventsAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getMemberEvents() {
  yield takeEvery(MemberActionType.GET_MEMBER_EVENTS_API, getMemberEventsAPI)
}

const getComingUpEventsRequest = async () => API.get('events/coming-up')
function* getEventsComingUpAPI() {
  try {
    const request = yield call(getComingUpEventsRequest)
    yield put(getComingUpEventsAPISuccess(request))
  } catch (error) {
    yield put(getComingUpEventsAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* getComingUpEvents() {
  yield takeEvery(MemberActionType.GET_COMING_UP_EVENTS_API, getEventsComingUpAPI)
}

const postMemberContactRequest = async () => API.post('contact')
function* postMemberContactAPI() {
  try {
    const request = yield call(postMemberContactRequest)
    yield put(postMemberContactAPISuccess(request))
  } catch (error) {
    yield put(postMemberContactAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* postMemberContact() {
  yield takeEvery(MemberActionType.POST_MEMBER_CONTACT_API, postMemberContactAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getMemberInvitations),
    fork(getMemberEvents),
    fork(postMemberContact),
    fork(getComingUpEvents),
  ])
}
