import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { LayoutBox, Title, ParallaxImage, ButtonInvite } from './../../components'
import {
  getInviteAPI, updateStatusMessageAPI, setMessagePropertyValue,
  getResponseOfInviteAPI, turnOffAPI,
} from './../../effects/actions'
import {useHistory} from "react-router"

const TextDiv = styled.div`
  padding: 8px;
  background: #262626;
  height: 32vh;
  color: #FFF;
  overflow-y: scroll;
  font-size: 15px;
  font-family: "Helvetica Neue LT W05 55 Roman";
  &::-webkit-scrollbar {
    display: none;
  }
`


const LearnMorePage = ({
  messageInvite, getInviteAPI, token, successUpdateStatus, setMessagePropertyValue,
  getResponseOfInviteAPI, updateStatusMessageAPI, status, statusId, turnOffAPI, turnedOffSuccess,
}) => {
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if((token && !messageInvite) || successUpdateStatus ){
      getInviteAPI(id)
      getResponseOfInviteAPI(id)
      if(successUpdateStatus) {
        setMessagePropertyValue("successUpdateStatus", null)
      }
    }
  }, [token, messageInvite, successUpdateStatus, getInviteAPI, id, getResponseOfInviteAPI, setMessagePropertyValue])

  useEffect(() => {
    if (turnedOffSuccess) {
      setMessagePropertyValue('turnedOffSuccess', null)
      history.push('/splash')
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [setMessagePropertyValue, turnedOffSuccess])

  const onConfirm = () => {
    updateStatusMessageAPI(id, 3)
  }

  const onConfirmIWontGo = () => {
    updateStatusMessageAPI(id, 4)
  }

  const handleOnCloseClick = () => {
    turnOffAPI(id)
  }

  const renderDate = (startDate, endDate) => {
    if(startDate && endDate){
      return ` on ${startDate.format("MMM Do")}` + startDate !== endDate && ` - ${endDate.format("MMM Do")}`
    }
    return null
  }

  if(messageInvite !== null){
    let startDate = messageInvite.event && messageInvite.event.start_date && moment(messageInvite.event.start_date)
    let endDate = messageInvite.event && messageInvite.event.end_date && moment(messageInvite.event.end_date)
    let title = messageInvite.event && messageInvite.event.headline
    let willGoButtonText = messageInvite && messageInvite.action_one_text
    let willGoButtonColor = messageInvite && messageInvite.action_one_color
    let willGoButtonBackground = messageInvite && messageInvite.action_one_background
    let wontGoButtonText = messageInvite && messageInvite.action_one_text
    let wontGoButtonColor = messageInvite && messageInvite.action_two_color
    let wontGoButtonBackground = messageInvite && messageInvite.action_two_background
    let location = messageInvite.event && messageInvite.event.location
    let description = messageInvite.event && messageInvite.event.description
    let image = messageInvite.event && messageInvite.event.media_detail
    if(image === null) image = messageInvite.event && messageInvite.event.media

    return (
      <LayoutBox showHeader styles={{ marginTop: "7vh"}} showBack showClose onClose={handleOnCloseClick}>
        <Title margin="0 0 1px 0" fontSize={"32px"}>
          {title}
        </Title>
        <Row>
          {image !== null && <Col xl={8} lg={8} sm={8} md={8} xs={8} style={{ padding: 8 }}>
              <ParallaxImage
                height={"32vh"}
                image={image}
              />
          </Col>}
          <Col xl={16} lg={16} sm={16} md={16} xs={16} style={{ padding: 8 }}>
            <TextDiv>
              {messageInvite.campaign
                && messageInvite.event
                && messageInvite.event.subheadline}
                {renderDate(startDate, endDate)} {`in ${location}. `}
              {description}
            </TextDiv>
          </Col>
        </Row>


        <ButtonInvite
          onConfirmImIn={onConfirm}
          onConfirmIWontGo={onConfirmIWontGo}
          statusId={statusId}
          id={id}
          status={status}
          showBack
          willGoButtonText={willGoButtonText}
          willGoButtonColor={willGoButtonColor}
          willGoButtonBackground={willGoButtonBackground}
          wontGoButtonText={wontGoButtonText}
          wontGoButtonColor={wontGoButtonColor}
          wontGoButtonBackground={wontGoButtonBackground}
          showContact={messageInvite && messageInvite.show_contact_me}
        />
      </LayoutBox>
    )
  }
  return <LayoutBox showHeader contentWithPadding={false} loading={true} />
}


LearnMorePage.propTypes = {
  messageInvite: PropTypes.object,
  token: PropTypes.string,
  getInviteAPI: PropTypes.func,
  successUpdateStatus: PropTypes.string,
  setMessagePropertyValue: PropTypes.func,
  getResponseOfInviteAPI: PropTypes.func,
  updateStatusMessageAPI: PropTypes.func,
  turnOffAPI: PropTypes.func,
  status: PropTypes.object,
  statusId: PropTypes.string,
}

const mapStateToProps = ({ messageAPI, authAPI }) => {
  const {
    token,
  } = authAPI
  const {
    messageInvite, successUpdateStatus, status, statusId, turnedOffLoading, turnedOffSuccess, turnedOffError,
  } = messageAPI
  return {
    token, messageInvite, successUpdateStatus, status, statusId, turnedOffLoading, turnedOffSuccess, turnedOffError,
  }
}

export default connect(mapStateToProps, {
  getInviteAPI,
  setMessagePropertyValue,
  getResponseOfInviteAPI,
  updateStatusMessageAPI,
  turnOffAPI,
})(LearnMorePage)
