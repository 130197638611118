import React from 'react'
import { Card, Typography } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import moment from 'moment'

const Space = styled.div`
    padding: 4px;
`

const CardStyled = styled(Card)`
    height: 230px;
    background: #262626 0% 0% no-repeat padding-box;
    border: none;
`



const CardInviteComponent = ({ invite }) => {
    const history = useHistory()
    const fontStyles = { color: "#fff", fontFamily: "'Helvetica Neue LT W05 55 Roman' !important" }
    const startDate = invite.campaign && invite.event.start_date && moment(invite.event.start_date)
    const endDate = invite.campaign && invite.event.end_date && moment(invite.event.end_date)
    let diff = moment.duration(endDate.diff(startDate))
    let quantityOfDays = diff.asDays()
    let quantityOfMonths = diff.asMonths()
    return (
        <Space>
            <CardStyled onClick={() => history.push(`/invite/${invite.id}`)}>
                <Typography.Title style={{...fontStyles, marginBottom: 0, fontSize: 18, textTransform: "uppercase", lineHeight: "40px" }}>
                    {startDate.format("MMM Do")} - {Math.round(quantityOfMonths) === 0 ? endDate.format("Do") : endDate.format("MMM Do")}
                </Typography.Title>
                <Typography.Title style={{...fontStyles, marginTop: 0, fontSize: 26}}>
                    {invite && invite.event && invite.event.headline.substring(0,10)}...
                </Typography.Title>
                <Typography.Title style={{...fontStyles, fontWeight: "normal", fontSize: 20, lineHeight: "30px", marginBottom: 0,  fontFamily: "'Helvetica Neue LT W05 55 Roman"}}>
                    {Math.round(quantityOfDays)} {quantityOfDays > 1 ? "Days" : "Day"} Event
                    <br />
                    {invite && invite.event && invite.event.location}
                </Typography.Title>
              
            </CardStyled>
        </Space>
    )
}

CardInviteComponent.propTypes = {
    invite: PropTypes.object,
}

  
export default CardInviteComponent
