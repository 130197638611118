import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const CenterContentComponent = ({ children, ...rest }) => {
  return (
      <Center {...rest}>
        {children}
      </Center>
  )
}


CenterContentComponent.propTypes = {
    children: PropTypes.any,
}

CenterContentComponent.defaultProps = {}

export default CenterContentComponent
