export const MemberActionType = {
  GET_MEMBER_INVITATIONS_API: 'MEMBER.GET_MEMBER_INVITATIONS_API',
  GET_MEMBER_INVITATIONS_API_SUCCESS: 'MEMBER.GET_MEMBER_INVITATIONS_API_SUCCESS',
  GET_MEMBER_INVITATIONS_API_FAILURE: 'MEMBER.GET_MEMBER_INVITATIONS_API_FAILURE',
  UPDATE_MEMBER_INVITATION_LIST: 'MEMBER.UPDATE_MEMBER_INVITATION_LIST',
  UPDATE_MEMBER_EVENTS_LIST: 'MEMBER.UPDATE_MEMBER_EVENTS_LIST',
  GET_MEMBER_EVENTS_API: 'MEMBER.GET_MEMBER_EVENTS_API',
  GET_MEMBER_EVENTS_API_SUCCESS: 'MEMBER.GET_MEMBER_EVENTS_API_SUCCESS',
  GET_MEMBER_EVENTS_API_FAILURE: 'MEMBER.GET_MEMBER_EVENTS_API_FAILURE',
  GET_COMING_UP_EVENTS_API: 'MEMBER.GET_COMING_UP_EVENTS_API',
  GET_COMING_UP_EVENTS_API_SUCCESS: 'MEMBER.GET_COMING_UP_EVENTS_API_SUCCESS',
  GET_COMING_UP_EVENTS_API_FAILURE: 'MEMBER.GET_COMING_UP_EVENTS_API_FAILURE',
  POST_MEMBER_CONTACT_API: 'MEMBER.POST_MEMBER_CONTACT_API',
  POST_MEMBER_CONTACT_API_SUCCESS: 'MEMBER.POST_MEMBER_CONTACT_API_SUCCESS',
  POST_MEMBER_CONTACT_API_FAILURE: 'MEMBER.POST_MEMBER_CONTACT_API_FAILURE',
}
