import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import {LayoutBox, ParallaxImage, Title} from '../../components'
import {getEventAPI} from '../../effects/actions'
import ButtonComingUpComponent from "../../components/button/coming-up"

const ComingUpPage = ({ event, getEventAPI, token }) => {
    const { id } = useParams()
    useEffect(() => {
        if((token && (!event || +event.id !== +id))) {
            getEventAPI(id)
        }
    }, [token, event, id, getEventAPI])

    if(event !== null){
        let {headline, media} = event

        return (
            <LayoutBox
                showHeader
                contentWithPadding={false}
                showBack
                showClose
                loading={false}
            >
               <>
                   <Title margin="2% 0 2% 0" fontSize={"32px"}>
                       {headline}
                   </Title>
                   <ParallaxImage
                       height={"100px"}
                       image={media}
                   />
                   <Title padding="1% 0 0 0"  margin="1% 0 0 0" fontSize={"22px"}>
                       {event.subheadline} in {event.location}
                   </Title>
                   <ButtonComingUpComponent
                       id={id}
                   />
               </>
            </LayoutBox>
        )
    }
    return  <LayoutBox showHeader contentWithPadding={false} showBack loading={true} />
}


ComingUpPage.propTypes = {
    event: PropTypes.object,
    token: PropTypes.string,
    getEventAPI: PropTypes.func,
    successUpdateStatus: PropTypes.string,
    loading: PropTypes.bool,
}

const mapStateToProps = ({ messageAPI, authAPI }) => {
    const { event, successUpdateStatus, loading} = messageAPI
    return { token: authAPI.token, event, successUpdateStatus, loading }
}

export default connect(mapStateToProps, {
    getEventAPI,
})(ComingUpPage)
