import React from 'react'
import { useEffect } from 'react'
import { CenterContent, LayoutBox, Title } from './../../components'
import {useHistory, useLocation} from 'react-router'
import {setMessagePropertyValue, turnOffAPI} from "../../effects/actions"
import {connect} from "react-redux"

const InviteConfirmPage = ({turnedOffSuccess, setMessagePropertyValue,  turnOffAPI}) => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      turnOffAPI(location.state.id)
    }, 60000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.id])

  useEffect(() => {
    if (turnedOffSuccess) {
      setMessagePropertyValue('turnedOffSuccess', null)
      history.push('/splash')
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [setMessagePropertyValue, turnedOffSuccess])

  const handleOnCloseClick = () => {
    turnOffAPI(location.state.id)
  }

  return (
    <LayoutBox showHeader showBack showClose onClose={handleOnCloseClick}>
      <CenterContent>
        <Title padding={"48px"} textalign={"center"}>
          {location.state.message}
        </Title>
      </CenterContent>
    </LayoutBox>
  )
}

const mapStateToProps = ({ messageAPI }) => {
  const {
    turnedOffLoading, turnedOffSuccess, turnedOffError,
  } = messageAPI
  return {
    turnedOffLoading, turnedOffSuccess, turnedOffError,
  }
}

export default connect(mapStateToProps, {
  turnOffAPI,
  setMessagePropertyValue,
})(InviteConfirmPage)
