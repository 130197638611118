import React from 'react'
import PropTypes from 'prop-types'
import { CenterContent, Title } from '..'
import { connect } from 'react-redux'

const WelcomeComponent = ({ loggedUser }) => {
  let name = loggedUser !== null ? loggedUser.name : ""
  return (
    <CenterContent >
      <Title padding={"48px"} textalign={"center"}>Welcome {name} </Title>
    </CenterContent>
  )
}

WelcomeComponent.propTypes = {
  children: PropTypes.any.isRequired,
  loggedUser: PropTypes.object,
}

WelcomeComponent.defaultProps = {}

const mapStateToProps = ({ authAPI }) => {
  const {
    loggedUser,
  } = authAPI
  return {
    loggedUser,
  }
}

export default connect(mapStateToProps, null)(WelcomeComponent)