export const MessageActionType = {
  GET_INVITE_API: 'MESSAGE.GET_INVITE_API',
  GET_INVITE_API_SUCCESS: 'MESSAGE.GET_INVITE_API_SUCCESS',
  GET_INVITE_API_FAILURE: 'MESSAGE.GET_INVITE_API_FAILURE',
  GET_EVENT_API: 'MESSAGE.GET_EVENT_API',
  GET_EVENT_API_SUCCESS: 'MESSAGE.GET_EVENT_API_SUCCESS',
  GET_EVENT_API_FAILURE: 'MESSAGE.GET_EVENT_API_FAILURE',
  UPDATE_STATUS_MESSAGE_API: 'MESSAGE.UPDATE_STATUS_MESSAGE_API',
  UPDATE_STATUS_MESSAGE_API_SUCCESS: 'MESSAGE.UPDATE_STATUS_MESSAGE_API_SUCCESS',
  UPDATE_STATUS_MESSAGE_API_FAILURE: 'MESSAGE.UPDATE_STATUS_MESSAGE_API_FAILURE',
  SET_PROPERTY_VALUE: 'MESSAGE.SET_PROPERTY_VALUE',
  GET_RESPONSE_OF_INVITE_API: 'MESSAGE.GET_RESPONSE_OF_INVITE_API',
  GET_RESPONSE_OF_INVITE_API_SUCCESS: 'MESSAGE.GET_RESPONSE_OF_INVITE_API_SUCCESS',
  GET_RESPONSE_OF_INVITE_API_FAILURE: 'MESSAGE.GET_RESPONSE_OF_INVITE_API_FAILURE',
  SET_MESSAGE_INVITE_ORIGINAL_PROPERTY_VALUE: 'MESSAGE.SET_MESSAGE_INVITE_ORIGINAL_PROPERTY_VALUE',
  GET_MISSED_NOTIFICATIONS_API: 'MESSAGE.GET_MISSED_NOTIFICATIONS_API',
  GET_MISSED_NOTIFICATIONS_API_SUCCESS: 'MESSAGE.GET_MISSED_NOTIFICATIONS_API_SUCCESS',
  GET_MISSED_NOTIFICATIONS_API_FAILURE: 'MESSAGE.GET_MISSED_NOTIFICATIONS_API_FAILURE',
  TURN_OFF_API: 'MESSAGE.TURN_OFF_API',
  TURN_OFF_API_SUCCESS: 'MESSAGE.TURN_OFF_API_SUCCESS',
  TURN_OFF_API_FAILURE: 'MESSAGE.TURN_OFF_API_FAILURE',
  TURN_ON_API: 'MESSAGE.TURN_ON_API',
  TURN_ON_API_SUCCESS: 'MESSAGE.TURN_ON_API_SUCCESS',
  TURN_ON_API_FAILURE: 'MESSAGE.TURN_ON_API_FAILURE',
  CONTACT_ME: 'MESSAGE.CONTACT_ME',
  CONTACT_ME_SUCCESS: 'MESSAGE.CONTACT_ME_SUCCESS',
  CONTACT_ME_FAILURE: 'MESSAGE.CONTACT_ME_FAILURE',
}
