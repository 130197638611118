import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'antd'

const TextStyled = styled(Typography.Text)`
    color: ${(props) => props.color};
    font-family: 'Helvetica Neue LT W05 55 Roman';
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
    margin: ${(props) => props.margin} !important;
`

const TextComponent = ({ children, ...props }) => {
  return (
    <TextStyled {...props}>
      {children}
    </TextStyled>
  )
}

TextComponent.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  margin: PropTypes.string,
}

TextComponent.defaultProps = {
  color: '#000',
  fontSize: "16px",
  fontWeight: "bold",
  margin: "0",
}

export default TextComponent
