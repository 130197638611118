import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {connect} from 'react-redux'
import { CenterContent, LayoutBox, Title } from './../../components'
import { postMemberContactAPI } from '../../effects/actions'

const ContactMePage = ({ postMemberContactAPI, loggedUser }) => {
  useEffect(() => {
    if(loggedUser){
      postMemberContactAPI()
      setTimeout(() => {
        window.location.href = '/'
      }, 36000)
    }
  }, [loggedUser, postMemberContactAPI])
  return (
    <LayoutBox showHeader showBack showClose>
      <CenterContent>
        <Title padding={"48px"} textalign={"center"}>
          Thanks! We will contact you shortly using your preferred method.
        </Title>
      </CenterContent>
    </LayoutBox>
  )
}

ContactMePage.propTypes = {
  loggedUser: PropTypes.string,
  postMemberContactAPI: PropTypes.func,
}

const mapStateToProps = ({ authAPI }) => {
  const {
    loggedUser,
  } = authAPI
  return {
    loggedUser,
  }
}

export default connect(mapStateToProps, {
  postMemberContactAPI,
})(ContactMePage)
