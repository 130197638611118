import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { menuItems } from './../../constants/menu'
import { Link } from 'react-router-dom'
import { CloseIcon } from '../icons'


const MenuStyled = styled.div`
    background: ${(props) => props.background};
    padding: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
`

const MenuItem = styled(Menu.Item)`
  margin-bottom: 1px;
  margin-top: 1px;
`

const Text = styled.span`
    color: #FFF;
    font: normal normal bold 16px/40px 'Helvetica Neue LT W05_75 Bold';
`

const MenuComponent = ({ children, logged, fillColor, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    props.onClose()
    setIsOpen(false)
  }


  if(isOpen)
    return (
      <MenuStyled {...props}>
        <Menu style={{ background: props.background, borderRight: 0}} theme={fillColor}>
          <MenuItem theme="dark">
            <div onClick={onClose}><CloseIcon /></div>
          </MenuItem>
          {menuItems.map((item, index) => {
            return (
              <MenuItem key={index} theme="dark">
                {item.external === true ?
                (
                  <a href={item.path}>
                    <Text>{item.title}</Text>
                  </a>
                )
                :
                (
                  <Link to={item.path}>
                    <Text>{item.title}</Text>
                  </Link>
                )
              }

              </MenuItem>
            )
          })}
        </Menu>
      </MenuStyled>
    )
  if(logged === true) return <MenuOutlined style={{ color: fillColor, fontSize: 30, margin: 5 }} onClick={onOpen} />
  return null
}

MenuComponent.propTypes = {
  children: PropTypes.any,
  background: PropTypes.string,
  onClose: PropTypes.func,
}

MenuComponent.defaultProps = {
  background: '#696969',
  children: null,
  onClose: () => {},
}

export default MenuComponent
