import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  signInAPIFailure,
  signInAPISuccess,
} from './../../actions'
import {
  AuthActionType,
} from './../../types'
import { API } from './../../../utils/api'
import { Util } from './../../../utils'

const signInRequest = async (macAddress) => API.post('auth/login/box', macAddress)
function* signInAPI({ payload }) {
  try {
    const request = yield call(signInRequest, payload)
    yield put(signInAPISuccess(request))
  } catch (error) {
    yield put(signInAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* signIn() {
  yield takeEvery(AuthActionType.SIGN_IN_API, signInAPI)
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
  ])
}
