import React from 'react'
import { Card, Typography } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router'

const Space = styled.div`
    padding: 4px;
`

const CardStyled = styled(Card)`
    height: 230px;
    background: #262626 0 0 no-repeat padding-box;
    border: none;
`

const ComingUpCardComponent = ({ event }) => {
    const history = useHistory()
    const fontStyles = { color: "#fff", fontFamily: "'Helvetica Neue LT W05 55 Roman' !important" }
    return (
        <Space>
            <CardStyled onClick={() => history.push(`/coming-up/${event.id}`)}>
                <Typography.Title style={{...fontStyles, marginTop: 0, fontSize: 26}}>
                    {event && event.headline.substring(0,10)}...
                </Typography.Title>
                <Typography.Title style={{...fontStyles, marginTop: 0, fontSize: 22}}>
                    {event && event.subheadline && event.subheadline.substring(0,10)}...
                </Typography.Title>
                <Typography.Title style={{...fontStyles, fontWeight: "normal", fontSize: 20, lineHeight: "30px", marginBottom: 0,  fontFamily: "'Helvetica Neue LT W05 55 Roman"}}>
                    {event && event.location}
                </Typography.Title>
            </CardStyled>
        </Space>
    )
}

ComingUpCardComponent.propTypes = {
    event: PropTypes.object,
}


export default ComingUpCardComponent
