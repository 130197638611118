import { MessageActionType } from './../../types'

const defaultState = {
  messageInvite: null,
  successMessage: null,
  errorMessage: null,
  loading: false,
  contactMeSuccessMessage: null,
  contactMeErrorMessage: null,
  contactMeLoading: false,
  successUpdateStatus: null,
  status: null,
  statusId: null,
  event: null,
  turnedOffSuccess: null,
  turnedOffError: null,
  turnedOffLoading: false,
  missedNotifications: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case MessageActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case MessageActionType.GET_INVITE_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_INVITE_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageInvite: action.payload.data,
      }

    case MessageActionType.GET_INVITE_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_EVENT_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_EVENT_API_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload.data,
      }

    case MessageActionType.GET_EVENT_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.UPDATE_STATUS_MESSAGE_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.UPDATE_STATUS_MESSAGE_API_SUCCESS:
      return {
        ...state,
        loading: false,
        successUpdateStatus: 'success',
        status: action.payload.data,
        statusId: action.payload.data.id,
      }

    case MessageActionType.UPDATE_STATUS_MESSAGE_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_MISSED_NOTIFICATIONS_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_MISSED_NOTIFICATIONS_API_SUCCESS:
      return {
        ...state,
        loading: false,
        missedNotifications: action.payload,
        successMessage: 'success.message',
      }

    case MessageActionType.GET_MISSED_NOTIFICATIONS_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_RESPONSE_OF_INVITE_API:
      return {
        ...state,
        loading: true,
        status: null,
      }

    case MessageActionType.GET_RESPONSE_OF_INVITE_API_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.data && action.payload.data.status,
        statusId: action.payload.data && action.payload.data.status && action.payload.data.status.id,
      }

    case MessageActionType.GET_RESPONSE_OF_INVITE_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.SET_MESSAGE_INVITE_ORIGINAL_PROPERTY_VALUE:
      return { ...state, messageInvite: defaultState.messageInvite }


    case MessageActionType.TURN_OFF_API:
      return {
        ...state,
        turnedOffLoading: true,
      }

    case MessageActionType.TURN_OFF_API_SUCCESS:
      return {
        ...state,
        turnedOffLoading: false,
        turnedOffSuccess: 'Lights turned off successfully!',
      }

    case MessageActionType.TURN_OFF_API_FAILURE:
      return { ...state, turnedOffLoading: false, turnedOffError: action.payload.data }

    case MessageActionType.TURN_ON_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.TURN_ON_API_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case MessageActionType.TURN_ON_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.CONTACT_ME:
      return {
        ...state,
        contactMeLoading: true,
      }

    case MessageActionType.CONTACT_ME_SUCCESS:
      return {
        ...state,
        contactMeLoading: false,
        contactMeSuccessMessage: 'Thanks! We will contact you shortly using your preferred method.',
      }

    case MessageActionType.CONTACT_ME_FAILURE:
      return { ...state, contactMeLoading: false, contactMeErrorMessage: action.payload.data }

    default: return { ...state }
  }
}
