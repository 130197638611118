import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {connect} from 'react-redux'
import { CenterContent, LayoutBox, Title } from './../../components'
import {getInviteAPI, turnOffAPI, setMessagePropertyValue} from '../../effects/actions'
import {useParams} from "react-router-dom"
import {useHistory} from "react-router"

const InviteContactMe = ({getInviteAPI, setMessagePropertyValue, loggedUser, messageInvite, turnOffAPI, turnedOffSuccess }) => {
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        if (turnedOffSuccess) {
            setMessagePropertyValue('turnedOffSuccess', null)
            history.push('/splash')
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [setMessagePropertyValue, turnedOffSuccess])

    useEffect(() => {
        if((loggedUser && !messageInvite) ){
            getInviteAPI(id)
        }
    }, [loggedUser, messageInvite, getInviteAPI, id])

    const handleOnCloseEvent = () => {
        turnOffAPI(id)
    }

    return (
        <LayoutBox showHeader showBack showClose onClose={handleOnCloseEvent}>
            <CenterContent>
                <Title padding={"48px"} textalign={"center"}>
                    Thanks! We will contact you shortly using your preferred method.
                </Title>
            </CenterContent>
        </LayoutBox>
    )
}

InviteContactMe.propTypes = {
    loggedUser: PropTypes.string,
    postMemberContactAPI: PropTypes.func,
}

const mapStateToProps = ({ authAPI, messageAPI }) => {
    const {
        loggedUser,
    } = authAPI
    const {
        messageInvite, turnedOffSuccess,
    } = messageAPI
    return {
        loggedUser,
        messageInvite,
        turnedOffSuccess,
    }
}

export default connect(mapStateToProps, {
    getInviteAPI,
    turnOffAPI,
    setMessagePropertyValue,
})(InviteContactMe)
