import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Detector} from "react-detect-offline"
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Layout} from 'antd'
import {useHistory} from 'react-router'
import {CloseOutlined, LeftOutlined, WifiOutlined} from '@ant-design/icons'
import {Container, Menu, NoWifiIcon} from './../../'
import {signOutAPI} from '../../../effects/actions'
import {LOCAL_BOX_URL} from '../../../constants/urls'
import LogoSvgComponent from "../../LogoSvgComponent"

const Loader = styled.div`
    background: #000;
    color: #fff;
    max-height: 100vh !important;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const LayoutContainer = styled(Layout)`
  min-height: 100vh;
  background: transparent;
`

const HeaderContainer = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 80px;
  padding: 0 45px;
`

const LogoContainer = styled.div`
  position: absolute;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  & svg {
    width: 100%;
    height: 100%;
  }
`

const Footer = styled(Layout.Footer)`
  position: fixed;
  height: 80px;
  background: transparent;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 45px;
`

const Content = styled(Layout.Content)`
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const NavigationBar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  & div {
    display: flex;
    flex: 1;
  }
`
const Left = styled.div`
`

const Right = styled.div`
  justify-content: flex-end;
`

const LayoutBoxComponent = ({
  children, contentWithPadding, logged, showHeader,
  showClose, showBack, onBack, loading, styles, onClose, iconColor,
}) => {
  const history = useHistory()
  const [offline, setOffline] = useState(false)

  useEffect(() => {
    if(offline){
      setTimeout(() => {
        window.location.href = 'http://localhost:3002'
      }, 1000 * 15)
    }
  }, [offline])

  const handleBackButtonClick = () => {
    // If onClose is passed as the callback method to be called, then use that else navigate to splash page
    if (typeof onBack === "function") {
      onBack()
    } else {
      history.goBack()
    }
  }

  const handleCloseButtonClick = () => {
    // If onClose is passed as the callback method to be called, then use that else navigate to splash page
    if (typeof onClose === "function") {
      onClose()
    } else {
      history.push('/splash')
    }
  }

  return (
    <LayoutContainer>
        {showHeader && (
          <HeaderContainer>
            <LogoContainer>
              {/*<img src={iconImg} alt="logo bl" />*/}
              <LogoSvgComponent fillColor={iconColor}/>
            </LogoContainer>
            <NavigationBar>
              { showBack && (
                  <Left onClick={handleBackButtonClick}>
                    <LeftOutlined style={{ fontSize: 24, color: "#fff"}}/>
                  </Left>
              )}
              { showClose && (
                  <Right onClick={handleCloseButtonClick}>
                    <CloseOutlined style={{ fontSize: 24, color: "#fff"}} />
                  </Right>
              )}
            </NavigationBar>
          </HeaderContainer>
        )}
        <Content>
          <Container padding={contentWithPadding ? 16 : 0} style={styles}>
            <Loader spinning={loading} tip="Loading...">
              {children}
            </Loader>
          </Container>
        </Content>
        <Footer>
          <NavigationBar>
            <Left>
              <Menu logged={logged} fillColor={iconColor} />
            </Left>
            <Right>
              <a href={`${LOCAL_BOX_URL}/?issetup=true`}>
                <Detector
                  onChange={(online) => {
                    setOffline(!online)
                  }}
                  render={({ online }) => (
                    !online
                        ? <NoWifiIcon fillColor={iconColor}/>
                        : <WifiOutlined style={{ color: iconColor, fontSize: 30, margin: 5}} />
                  )}
                />
              </a>
            </Right>
          </NavigationBar>
        </Footer>
    </LayoutContainer>
  )
}

LayoutBoxComponent.propTypes = {
  children: PropTypes.any.isRequired,
  signOutAPI: PropTypes.func,
  logged: PropTypes.bool,
  showHeader: PropTypes.bool,
  showClose: PropTypes.bool,
  contentWithPadding: PropTypes.bool,
  showBack: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  styles: PropTypes.any,
}

LayoutBoxComponent.defaultProps = {
  logged: true,
  showHeader: false,
  iconColor: '#ffffff',
  showClose: false,
  contentWithPadding: true,
  loading: false,
  styles: {},
}
export default
connect(null, {
  signOutAPI,
})(LayoutBoxComponent)
