import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getSplashesAPIFailure,
  getSplashesAPISuccess,
} from './../../actions'
import {
  SplashActionType,
} from './../../types'
import { API } from './../../../utils/api'

const getSplashesRequest = async (memberId ) => API.get(`all-splashes/${memberId}`)
function* getSplashesAPI({payload}) {
  try {
    const request = yield call(getSplashesRequest, payload.id)
    yield put(getSplashesAPISuccess(request.data))
  } catch (error) {
    yield put(getSplashesAPIFailure(error))
  }
}

export function* getSplashes() {
  yield takeEvery(SplashActionType.GET_SPLASHES_API, getSplashesAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getSplashes),
  ])
}
